import Network from '@/services/network'
import { ref } from 'vue'

export default function handlePayment() {
    const network = new Network;
    const loading = ref(false)

    const fetchSingleContra = (ledgerMasterId) => {
      return network.api('get', '/contras/'+ledgerMasterId);
    }

    const createContra = (data) => {
      return network.api('post', '/contras', data);
    }

    const editContra = (id, data) => {
      return network.api('post', '/contras/'+id, data);
    }

    const fetchAccountHeads = (companyId) => {
      return network.api('get', '/heads/cash-and-banks?company_id='+companyId);
    }

    return {
        fetchSingleContra,
        createContra,
        fetchAccountHeads,
        editContra,
        loading
    }
}