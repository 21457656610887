
    
import handlePayment from "@/services/modules/payment";
import handleContra from "@/services/modules/contra";
import handleReceipt from "@/services/modules/receipt";
import handleJournal from "@/services/modules/journal"; 
import handleCompany from "@/services/modules/company";
import ngoPdfPrinter from '@/services/utils/ngoPdfPrinter';
import handleBarcode from "@/services/modules/barcode";

import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";



export const useVoucherPrint = () => {
    const { fetchCompanyInfo } = handleCompany();
    const {fetchSinglePayment} = handlePayment();
    const { fetchSingleContra } = handleContra();
    const { fetchSingleReceipt } = handleReceipt();
    const { fetchJournal } = handleJournal();
    const { exportToPDF } = ngoPdfPrinter();
    const { fetchBarcode } = handleBarcode();
    
    const route = useRoute();
    const router = useRouter();
    const companyInfo = ref({});
    const payment = ref({});
    const barcodeQuery = ref('')
    const barcode = ref('')
    const qrcode = ref('');
    const voucherInfo = ref({});

    const vouchers = {
        contra_voucher: {
            route: "np-contra-voucher-edit",
            idParams: "contraId",
            pdfType: "contra",
            vMethod: fetchSingleContra,
        },
        journal_voucher: {
            route: "edit-journal-np",
            idParams: "journalId",
            pdfType: "journal",
            vMethod: fetchJournal
        },
        payment_voucher: {
            route: "ngo-payment-voucher-edit",
            idParams: "paymentId",
            pdfType: "payment",
            vMethod: fetchSinglePayment
        },
        receipt_voucher: {
            route: "receipt-voucher-np-edit",
            idParams: "receiptId",
            pdfType: "receipt",
            vMethod: fetchSingleReceipt
        },
    }


    const getType = (voucherNo) => {
        const prefix = voucherNo.substring(0, 2);
        switch (prefix) {
            case 'CV':
                return 'contra_voucher';
            case 'JV':
                return 'journal_voucher';
            case 'PV':
                return 'payment_voucher';
            case 'RV':
                return 'receipt_voucher'
        }
    }
    
    fetchCompanyInfo(route.params.companyId).then( (res) => {
            companyInfo.value = res.data;
        }).catch( (err) => {
            console.log(err);
    })

    const printVoucher = (id, type) => {

        const fetchVoucherByType = vouchers[type].vMethod;
        const companyQuery = `?company_id=${route.params.companyId}`;
        const pdfType = vouchers[type].pdfType;

        fetchVoucherByType(id, companyQuery).then( (res) => {
            if(res){
                voucherInfo.value = res.data;
            let qrcodeData = JSON.stringify({
                ref_no: voucherInfo.value.voucher_no,
                ref_date: voucherInfo.value.date,
                created_by: voucherInfo.value.user.name,
                created_date_time: formatDate(new Date())
            })
                barcodeQuery.value = `?code=${voucherInfo.value.voucher_no}&qrcode=${qrcodeData}`;
            }}).then (() => {
            fetchBarcode(barcodeQuery.value).then( (res) => {
                barcode.value = res.barcode;
                qrcode.value = res.qrcode;
            }).then( () => {
                exportToPDF(companyInfo.value, voucherInfo.value, barcode.value, qrcode.value, pdfType);
            })}).catch((err) => {
            console.log(err);
        })
    }

    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    return {
        printVoucher,
        vouchers,
        getType
    }
}